#about-me-parent {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  b {
    font-weight: 500;
  }

  .about-me-card {
    //background: var(--about-me);
    border-radius: 5px;
    margin: 0 5px 0;
    padding: 10px;
    width: fit-content;
    color: var(--text);
  }

  #about-me {
    //background: var(--about-me);
    border-radius: 5px;
    padding: 15px;
    width: fit-content;

    #info-parent {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 5px;
      max-width: 105ch;

      @media screen and (max-width: 660px) {
        grid-template-columns: 1fr;
      }
    }

    h1 {
      font-family: "Fira Code", monospace;
      font-weight: 500;
      font-size: 46px;
      color: var(--text);
    }

    h2 {
      font-family: "Fira Code", monospace;
      line-height: normal;
      margin-bottom: 0;
      font-weight: 400;
      color: var(--text);

      text-align: left;
    }

    p {
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      margin: 0;
      text-align: left;
    }

    ul {
      text-align: left;
      padding-left: 30px;
      margin-bottom: 0;
    }

    li {
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      list-style-type: symbols(">");
      list-style-position: inside;
    }
    li::-moz-list-bullet {
      content: "> ";
    }
  }

  button {
    background: none!important;
    border: none;
    padding: 0!important;
    color: var(--link-color);
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 0.15em;
    text-decoration-color:  rgba(0, 0, 0, 0);
    transition: text-decoration 300ms;

    &:hover, &:focus, &:focus-visible {
      text-decoration-color: var(--link-color);
      border: none;
    }
  }

  a {
    color: var(--link-color);
    text-decoration: underline;
    text-decoration-thickness: 0.15em;
    text-decoration-color:  rgba(0, 0, 0, 0);
    transition: text-decoration 300ms;

    &:hover, &:focus, &:focus-visible {
      text-decoration-color: var(--link-color);
    }
  }

  #now-playing {
    max-width: 25ch;
    text-indent: -1.2ch;
  }
}


