@import "~include-media/dist/_include-media.scss";

$breakpoints: (x-small: 520px, small: 760px, medium: 1100px, large: 1400px, x-large: 1560px, xx-large: 2100px);

// Override Sass min()
@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}

// Override Sass max()
@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}


#project-grid-parent {
  background: var(--grid-background);
  border-radius: 5px;
  margin: 10px;
  padding-left: 5px;
  padding-right: 5px;
  //background: var(--grid-background);


  @include media(">=medium") {
    width: 95%;
    margin: auto;
  }

  @include media(">=large") {
    width: 90%;
    margin: auto;
  }

  @include media(">=x-large") {
    width: 85%;
    margin: auto;
  }

  @include media(">=xx-large") {
    width: 80%;
    max-width: 1900px;
    margin: auto;
  }


  & > #project-header {
    background: var(--grid-header-background);
    border-radius: 5px 5px 0 0;
    margin-bottom: 2px;


    padding: 5px;

    * {
      color: var(--grid-header-text);
    }

    h2 {
      margin-bottom: 0;
      font-family: "Fira Code", monospace;
      font-size: 30px;
    }

    p {
      margin: 0 0 3px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
    }

  }
}


#project-grid {

  margin: auto;
  @include media("<=x-small") {
    .GridSizer, .OneWide, .FourByOne, .Medium {
      width: 100%;
    }
  }

  @include media(">x-small", "<small") {
    .GridSizer, .OneWide, .Medium {
      width: 49.5%;
    }
    .FourByOne {
      width: 100%;
    }
  }

  @include media(">=small", "<medium") {
    .GridSizer, .OneWide, .Medium {
      width: 33.3%;
    }
    .FourByOne {
      width: 66.6%;
    }
  }

  @include media(">=medium", "<large") {
    .GridSizer, .OneWide {
      width: 24.9%;
    }
    .Medium {
      width: 49.8%;
    }
    .FourByOne {
      width: 74.6%;
    }
  }

  @include media(">=large", "<x-large") {
    .GridSizer, .OneWide {
      width: 19.9%;
    }
    .Medium {
      width: 39.8%;
    }
    .FourByOne {
      width: 59.5%;
    }
  }

  @include media(">=x-large", "<xx-large") {
    .GridSizer, .OneWide {
      width: 20%;
    }
    .Medium {
      width: 40%;
    }
    .FourByOne {
      width: 60%;
    }
  }

  @include media(">=xx-large") {
    .GridSizer, .OneWide {
      width: 16.6%;
    }
    .Medium {
      width: 33%;
    }
    .FourByOne {
      width: 49.8%;
    }
  }
}

.GridElement {
  padding: 10px;


  .GridElementInternal {
    padding: 9px;
    border-radius: 5px;
    border: var(--grid-element-border);
    border-width: 2px;
    border-style: solid;
    background: var(--grid-element-background);

    box-shadow: 0 1px 2px var(--grid-element-shadow);
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      box-shadow: 0 2px 5px var(--grid-element-shadow);
    }

    &:focus {
      border: 2px solid lightblue;
    }
  }

  h2 {
    font-family: "Fira Code", monospace;
    font-weight: 400;
    margin-bottom: 0px;
    text-shadow: 0px 0px 1px var(--grid-element-header);
    color: var(--grid-element-header);
    line-height: normal;
  }

  h3 {
    line-height: normal;
    color: var(--grid-element-subheader);
    margin-bottom: 3px;
  }

  p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: var(--text);
    margin-bottom: 0;
  }
}

.OneWide {
  display: flex;
  flex-direction: column;
  width: available;

  .image {
    margin-bottom: 5px;
    width: 100%;
    max-width: 225px;
  }

  img {
    width: 100%;
    margin-bottom: 5px;
  }

  .GridElementInternal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.Medium {
  img {
    width: 100%;
    margin-bottom: 5px;
  }

  .image {
    max-width: 225px;
  }

  @include media("<medium") {
    .GridElementInternal {
      display: flex;
      flex-direction: column;
      width: available;
      align-items: center;
    }


    img {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  @include media(">=medium") {
    // Two column sort of layout
    .image {
      float: left;
      width: 50%;
      shape-outside: inset(0);

      img {
        margin-bottom: 0;
      }
    }

    .description {
      display: inline;

      p {
        text-align: justify;
      }

      h2 h3 {
        display: inline;
      }
    }
  }

  @include media(">x-large") {
    .image {
      width: 40%;
      padding-right: 10px;
    }
  }
}

.FourByOne {
  .GridElementInternal {
    display: flex;
    align-items: center;

    .image {
      flex: 1;

      img {
        width: 100%;
        max-width: 100%;
      }
    }

    .description {
      min-width: 20ch;
      width: 30ch;
    }

    @include media(">medium") {
      flex-direction: row;

    }
    @include media("<=medium") {
      flex-direction: column;
      .description {
        width: 95%;
      }
    }
  }
}

.LinkIcon {
  position: absolute;
  right: 16px;
  top: 15px;
  font-size: 24px;

  background: var(--chainlink-icon-background);
  border-radius: 5px;
}

svg {
  fill: var(--chainlink-icon-color);
}
